@import "./sass/global";
@import "./app";

:root {
  --font-family: "Inter", sans-serif;
  --font-family-accent: "Epilogue", serif;

  --gradient-header: linear-gradient(
    178.35deg,
    rgba(255, 215, 179, 0.5) 0.29%,
    rgba(255, 165, 165, 0.255208) 13.88%,
    rgba(243, 130, 218, 0) 25.04%
  );

  --gradient-accent-text: linear-gradient(
    192.9deg,
    #2fa7f7 4.81%,
    #84a1ff 48.42%,
    #9f77f2 94.84%
  );

  --gradient-header: linear-gradient(
    180deg,
    rgba(253, 209, 199, 0.6) 0%,
    rgba(255, 210, 245, 0.3) 56%,
    rgba(225, 208, 247, 0) 90%
  );

  --gradient-project: linear-gradient(
    180deg,
    rgba(253, 209, 199, 1) 0%,
    rgba(255, 210, 245, 1) 56%,
    rgba(225, 208, 247, 1) 100%
  );

  --gradient-background: linear-gradient(
    360deg,
    #e6f7ff 15%,
    rgba(245, 250, 252, 0) 80%
  );

  --color-secondary: #329cd9;
  --color-tertiary: #e56f00;
  --color-disabled: #777f9a;

  --color-foreground: #0b1426;
  --color-foreground80: #0b142680;
  --color-foreground50: #0b142650;
  --color-foreground25: #0b142625;

  --color-background: #ffffff;
  --color-background0: rgba(255, 255, 255, 0);
  --color-background80: rgba(255, 255, 255, 0.8);
  --color-background90: rgba(255, 255, 255, 0.9);

  --color-inactive: #758a9e;
  --color-border: #c7e1ed;
  --color-border30: #c7e1ed30;

  --box-shadow: 4px 4px 24px rgba(7, 44, 84, 0.2);
  --img-shadow: 2px 2px 16px rgba(7, 44, 84, 0.15);
  --box-glow: -8px -8px 24px #ffffff;
  --box-inset: inset 4px 4px 16px #fafafa50;

  --max-width: 800px;
  --content-width: 592px;

  --space-ns: -2rem;
  --space-xs: 1rem;
  --space-s: 2rem;
  --space-m: 3rem;
  --space-l: 6rem;
  --space-xl: 12rem;

  --size-nxs: -4px;
  --size-ns: -8px;
  --size-nl: -32px;
  --size-nicon: -36px;

  --size-xs: 4px;
  --size-s: 8px;
  --size-m: 16px;
  --size-l: 32px;
  --size-icon: 36px;
  --size-xl: 64px;

  --font-size-h1: 42px;
  --font-size-h2: 36px;
  --font-size-h3: 28px;
  --font-size-p: 21px;
  --font-size-caption: 18px;
}

[data-theme="dark"] {
  --gradient-header: linear-gradient(
    178deg,
    rgba(74, 30, 5, 0.8) 0%,
    rgba(101, 33, 76, 0.4) 40%,
    rgba(31, 4, 66, 0) 90%
  );

  --gradient-accent-text: linear-gradient(
    192.9deg,
    #2fa7f7 4.81%,
    #84a1ff 48.42%,
    #9f77f2 94.84%
  );
  --gradient-project: linear-gradient(
    180deg,
    #9b0b25 0%,
    #5f0a56 43.4%,
    #07116b 100%
  );
  --gradient-background: #091c2b;

  --box-shadow: 8px 8px 24px rgba(3, 16, 27, 0.8);
  --img-shadow: 8px 8px 16px rgba(3, 16, 27, 0.6);
  --box-glow: -8px -8px 24px #fafafa15;
  --box-inset: inset 4px 4px 16px #fafafa10;

  --color-foreground: #fafafa;
  --color-foreground80: #fafafa80;
  --color-foreground50: #fafafa50;
  --color-foreground25: #fafafa25;

  --color-secondary: #43b5f8;
  --color-tertiary: #ed9200;

  --color-background: #091c2b;
  --color-background00: #091c2b00;
  --color-background80: #091c2b80;
  --color-background90: #091c2b90;

  --color-inactive: #b6b9c2;
  --color-border: #302f68;
  --color-border30: #302f6830;
}
