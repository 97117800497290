@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,400;0,500;0,700;0,900;1,900&family=Inter:wght@300;400;500;700&display=swap");

h1 {
  font-family: var(--font-family-accent);
  font-size: var(--font-size-h1);
  font-weight: 500;
  line-height: 1.3;

  @include transition(color, 200ms);
}

h1 b,
h2 b {
  @include gradient-text();
  font-style: italic;
  font-weight: 900;
}

h2 {
  font-family: var(--font-family-accent);
  font-size: var(--font-size-h2);
  font-weight: 500;
  line-height: 1.3;

  @include transition(color, 200ms);
}

h3 {
  font-family: var(--font-family-accent);
  font-size: var(--font-size-h3);
  font-weight: 500;
  line-height: 1.3;
}

p,
li {
  font-family: var(--font-family);
  font-size: var(--font-size-p);
  line-height: 1.5;
  color: var(--color-foreground);

  @include transition(color, 200ms);
}

p a {
  font-weight: 700;
}

p a:hover {
  color: var(--color-secondary);
}
