@import "../../sass/mixins";

.navContainer {
  background: var(--gradient-header);
  position: fixed;
  z-index: 99;
  width: 100%;
}

nav {
  @include max-width();
  @include transition(transform 200ms);
  position: sticky;
  top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  font-family: var(--font-family-accent);
  font-size: var(--font-size-p);
  padding: var(--space-xs) 0;
  font-weight: 700;

  .logoContainer,
  .linksContainer {
    border-radius: 500px;
    padding: var(--size-m) var(--size-l) calc(var(--size-m) - var(--size-xs))
      var(--size-l);
    background: var(--color-background90);
    @include transition(background-color 150ms);
  }

  .logoContainer {
    margin-left: var(--size-nl);
  }

  &.navbarNeutral {
    .logoContainer,
    .linksContainer {
      background: var(--color-background0);
    }
  }

  &.navbarHidden {
    transform: translateY(-100%);
  }
}

.logo {
  font-weight: 900;
  color: var(--color-secondary);
}

.logo,
.links a {
  line-height: 1.3;
  transition: 200ms;
}

.links,
.navLinks {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-s);
  color: var(--color-inactive);

  a:hover {
    color: var(--color-foreground);
  }

  button.menu {
    display: none;
  }

  button {
    border: transparent;
    background: transparent;
    border-radius: 100%;
    padding: var(--size-xs);
    width: var(--size-icon);
    height: var(--size-icon);
    align-self: flex-start;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--size-nxs);

    .icon {
      width: var(--size-l);
      height: var(--size-l);
      stroke: var(--color-inactive);
    }

    &:hover {
      background: var(--color-foreground);

      .icon {
        stroke: var(--color-background);
        fill: #ed9200;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  nav {
    @include transition(all 200ms);
    .logoContainer {
      margin-left: 0;
    }

    &.navbarActive,
    &.navbarNeutral,
    &.navbarHidden {
      .logoContainer,
      .linksContainer {
        background: transparent;
        padding: 0;
      }
    }

    &.navbarNeutral,
    &.navbarHidden {
      background: var(--color-background00);
    }

    &.navbarActive {
      background: var(--color-background80);
    }
  }

  .links {
    .navLinks {
      display: none;
    }

    button .icon {
      stroke: var(--color-foreground);
      color: var(--color-foreground);
    }

    button.menu {
      display: flex;

      &:hover {
        background: var(--color-foreground);

        .icon {
          fill: var(--color-background);
        }
      }
    }
  }

  .menuContainer {
    width: 100%;
    background: var(--color-background);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--space-s);

      p.caption,
      button {
        @include caption();
        font-weight: 400;
      }

      button {
        .icon {
          width: calc(var(--size-l) - var(--size-s));
          height: calc(var(--size-l) - var(--size-s));
          margin-left: var(--size-xs);
        }
      }
    }

    ul.menuLinks li {
      padding: 0 var(--space-s) var(--space-s) var(--space-s);
      text-align: left;
      list-style-type: none;
      font-weight: 700;
    }
  }
}
