// @import "../../sass/mixins";

@mixin max-width() {
  max-width: var(--max-width);
  box-sizing: content-box;
  margin: auto;

  @media screen and (max-width: 550px) {
    width: 100%;
    padding: var(--space-s);
    box-sizing: border-box;
  }
}

@mixin content-width() {
  max-width: var(--content-width);
  box-sizing: content-box;

  @media screen and (max-width: 550px) {
    width: 100%;
    box-sizing: border-box;
  }
}

@mixin section-spacing($direction: bottom) {
  padding-#{$direction}: var(--space-l);

  @media screen and (max-width: 550px) {
    padding-#{$direction}: var(--space-m);
  }
}

@mixin paragraph-spacing() {
  margin-bottom: var(--space-s);
}

@mixin list-spacing() {
  margin-bottom: var(--size-s);
}

@mixin container-spacing() {
  padding-top: var(--space-xl);
  @media screen and (max-width: 550px) {
    padding-top: var(--space-l);
  }
}

@mixin heading-spacing() {
  margin-bottom: var(--space-s);
}

@mixin caption() {
  font-family: var(--font-family);
  line-height: 1.4;
  font-size: var(--font-size-caption);
  color: var(--color-inactive);
  margin: 0;
}

@mixin gradient-text($color: var(--gradient-accent-text)) {
  background: $color;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin shadow() {
  filter: drop-shadow(var(--box-shadow));
  -webkit-filter: drop-shadow(var(--box-shadow));
}

@mixin img-shadow() {
  filter: drop-shadow(var(--img-shadow));
  -webkit-filter: drop-shadow(var(--img-shadow));
}

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
