@import "../../sass/mixins";

.contact {
  margin: var(--space-m) 0;
  display: flex;
  align-items: center;
  gap: var(--space-m);
  flex-wrap: wrap;

  .icon,
  .logoIcon {
    color: var(--color-inactive);

    &:hover {
      color: var(--color-foreground);
    }
  }

  .icon {
    width: var(--size-icon);
    height: var(--size-icon);
  }

  .logoIcon {
    width: var(--size-l);
    height: var(--size-l);
  }
}

.tooltip {
  @include caption();
}
