@import "../../sass/mixins";

.form {
  label p,
  p.error {
    @include caption();
    margin-left: calc(var(--size-l) + var(--size-m) + var(--size-s));
  }

  label p {
    margin-bottom: var(--size-s);
    color: var(--color-inactive);
  }

  p.error {
    margin-top: var(--size-s);
    color: var(--color-tertiary);
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--size-s);

    .icon {
      width: var(--size-l);
      height: var(--size-l);
      color: var(--color-foreground80);
    }

    .textField,
    .button {
      height: var(--space-m);
      border-radius: var(--size-s);
      border: 1px solid var(--color-foreground50);
      font-family: var(--font-family);
      font-size: var(--font-size-p);

      padding: var(--size-m);
    }

    // .textField {
    // }

    .button {
      @include transition(all 250ms);
      font-weight: 900;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-foreground);

      background: var(--color-border30);
      border: 1px solid var(--color-border);

      &::before {
        @include transition(all 200ms);
        position: absolute;
        border-radius: var(--size-s);
        content: "";
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0;
        background: var(--gradient-project);
      }

      &:hover {
        @include transition(all 250ms);

        border: transparent;
        box-shadow: var(--box-glow), var(--box-shadow), var(--box-inset);

        &::before {
          opacity: 1;
        }
      }
    }
  }
}
