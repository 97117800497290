* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--gradient-background);
  color: var(--color-foreground);
}

a {
  color: unset;
  text-decoration: none;
  transition: 200ms;

  &.active {
    color: var(--color-foreground);
    border-bottom: 3px solid var(--color-foreground);
    padding-top: 3px;
  }
}

button {
  background: transparent;
  border: transparent;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  cursor: pointer;
}
