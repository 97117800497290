@import "../../sass/mixins";

.content {
  @include max-width();
  padding-bottom: var(--size-s);

  .caption {
    @include caption();

    .logo {
      width: var(--space-s);
      height: var(--space-s);
    }
  }
}
