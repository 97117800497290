@import "../../sass/mixins";

.container {
  @include container-spacing();
  @include transition(all 300ms);

  position: relative;
  background: var(--gradient-header);
}

.header {
  @include max-width();
}

.content {
  @include content-width();
  @include section-spacing();

  h1 {
    @include heading-spacing();
  }

  p {
    @include paragraph-spacing();
  }
}
